<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/jishu/xuanxing_banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <el-row :gutter="50">
                <el-col :span="8" :xs="24" v-for="(item,index) in list" :key="index">
                    <div class="xxlist">
                        <el-row>
                            <el-col :span="7">
                                <div class="xximage">
                                    <el-image style="width:100%" :src="item.image"></el-image>
                                </div>
                            </el-col>
                            <el-col :span="17">
                                <div class="xxright" >
                                    <div class="xxtitle">{{item.catalogue_title}}</div>
                                    <div class="xxbtn" @click="xiazai(item.catalogue_img)">{{$t('download')}}</div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { catalogueList} from "@/api/api"; 
export default {
    data(){
        return{
            list:[],
            title:''
        }
    },
    created(){
        this.title = this.$t('layout.model')+'-'+this.$t('seo.title')
        this.getList()
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    methods:{
        async getList(){
            const{data:res} = await catalogueList()
            res.forEach((item,index)=>{
                item.image = process.env.VUE_APP_BASE_API+item.image
            })
            this.list = res
        },
        xiazai(data){
            let url = process.env.VUE_APP_BASE_API+data
            window.open(url)
        }
    }
}
</script>
<style lang="scss" scoped>

    .appmain{
        width: 74%;
        background: #fff;
        margin: 0 auto;
        margin-top: 30px;
        padding-left: 5%;
        padding-top: 3%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 80%;
        }
    }
    .xxlist{
        margin-bottom: 50px;
        .xximage{
            width: 100%;
        }
        .xxright{
            margin-left: 20px;
            .xxtitle{
                margin-top: 2%;
                font-size: 18px;
                font-weight: 400;
                line-height: 20px;
                font-weight: bold;
                padding-bottom: 32%;
            }
            .xxbtn{
                width: 100px;
                height: 34px;
                background: #0052D9;
                color: #fff;
                text-align: center;
                line-height: 34px;
                font-size: 14px;
                cursor: pointer;
            }
        }
        
    }
</style>